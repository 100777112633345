<template>
  <v-app-bar
    app
    color="white"
    :height="scrollPosition > 50 ? '56px' : '80px'"
    elevate-on-scroll
  >
    <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" class="mr-1" color="rojobb" x-large @click="sheet = !sheet"></v-app-bar-nav-icon>
    <v-bottom-sheet v-model="sheet">
      <v-sheet
        class="text-center pa-6"
      >
        <div v-for="(item, index) in buttons" :key="index">
          <v-btn
            class="body-1 font-weight-medium rojobb--text"
            text
            block
            x-large
            @click="goAndClose(item.to)"
          >{{ item.name }}</v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <div class="d-flex">
      <v-img
        :width="scrollPosition > 50 ? '120' : '150'"
        style="cursor: pointer"
        src="../assets/borigenbetzel.webp"
        @click="$vuetify.goTo('#home')"
      ></v-img>
    </div>

    <v-spacer></v-spacer>

    <template v-if="!$vuetify.breakpoint.smAndDown">
      <div v-for="(item, index) in buttons" :key="index">
        <v-btn
          :large="scrollPosition < 50"
          :class="scrollPosition < 50 ? 'title font-weight-medium' : 'body-1 font-weight-bold'"
          class="rojobb--text"
          text
          @click="$vuetify.goTo(item.to)"
        >{{ item.name }}</v-btn>
      </div>
    </template>

    <v-spacer></v-spacer>

    <div class="text-right" :style="scrollPosition > 50 ? 'width: 120px' : 'width: 150px'">
      <v-btn
        dark
        large
        :x-large="scrollPosition < 50"
        class="font-weight-medium text-center text-none"
        :class="$vuetify.breakpoint.smAndDown ? 'body-2 px-1' : 'body-1'"
        color="rojobb"
        href="https://rrhh.borigenbetzel.com.ar"
        target="_blank"
      >Trabajá con<br/>Nosotros</v-btn>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    sheet: false,
    scrollPosition: null,
    buttons: [
      { name: 'Quiénes somos', to: '#about' },
      { name: 'Historia', to: '#timeline' },
      { name: 'Empresas', to: '#company' },
    ],
  }),
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
       this.scrollPosition = window.scrollY
    },
    goAndClose(to) {
      this.$vuetify.goTo(to)
      this.sheet = false
    }
  }
}
</script>

<style>
  .v-toolbar--prominent .v-toolbar__content {
    align-items: center !important;
  }
</style>