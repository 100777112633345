<template>
  <v-footer
    dark
    padless
  >
    <v-card
      class="flex"
      flat
      tile
    >
      <v-card-text class="rojobb white--text body-2 font-weight-thin">
        <v-row no-gutters class="text-center" style="font-size: 13px">
          <v-col cols="12" md="4">
            <h1 class="body-2 text-decoration-underline mb-2">CONTACTO</h1>
            <div>
              <v-icon left small>fas fa-map-marker-alt</v-icon>
              <a href="https://goo.gl/maps/groGGqoqacmTZEVbA" target="_blank" class="white--text text-decoration-none">Av. Belgrano 839, A4400 Salta</a>
            </div>
            <div>
              <v-icon left small>fas fa-phone</v-icon>+543874312030
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <h1 :class="$vuetify.breakpoint.smAndDown ? 'body-2 text-decoration-underline my-2' : 'body-2 text-decoration-underline mb-2'">PÁGINAS</h1>
            <v-row no-gutters>
              <v-col class="d-flex justify-end mr-2">
                <div class="text-left">
                  <div>
                    <a href="https://www.chevroletdycar.com.ar" target="_blank" class="white--text text-decoration-none">Chevrolet Dycar</a>
                  </div>
                  <div>
                    <a href="https://citroen.dycar.com.ar" target="_blank" class="white--text text-decoration-none">Citroën Dycar</a>
                  </div>
                  <div>
                    <a href="https://usados.dycar.com.ar" target="_blank" class="white--text text-decoration-none">Usados Dycar</a>
                  </div>
                </div>
              </v-col>
              <v-col class="text-left ml-2">
                <div>
                  <a href="https://celushop.com.ar" target="_blank" class="white--text text-decoration-none">CeluShop</a>
                </div>
                <div>
                  <a href="https://www.radarentacar.com.ar" target="_blank" class="white--text text-decoration-none">RADA</a>
                </div>
                <!-- <div>
                  <a href="https://www.4067autos.com.ar" target="_blank" class="white--text text-decoration-none">4067 Automotores</a>
                </div> -->
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4">
            <h1 :class="$vuetify.breakpoint.smAndDown ? 'body-2 text-decoration-underline my-2' : 'body-2 text-decoration-underline mb-2'">RRHH</h1>
            <a href="https://rrhh.borigenbetzel.com.ar" target="_blank" class="white--text text-decoration-none">Trabajá con nosotros</a>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="py-1 white--text text-center font-weight-thin" style="font-size: 12px">
        <div v-if="$vuetify.breakpoint.smAndDown">
          <div>Borigen Betzel Argentina</div>
          <div>Copyright © {{ new Date().getFullYear() }} Todos los derechos reservados</div>
        </div>
        <div v-else>
          Copyright © {{ new Date().getFullYear() }} Todos los derechos reservados | Borigen Betzel Argentina
        </div>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
    }),
  }
</script>