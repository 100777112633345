import firebase from 'firebase/app'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyBghcGcHcSkNAd57dr1bICPWVfLJKVvBYw",
  authDomain: "bb-home-c2770.firebaseapp.com",
  projectId: "bb-home-c2770",
  storageBucket: "bb-home-c2770.appspot.com",
  messagingSenderId: "972290695353",
  appId: "1:972290695353:web:4630eb3e850581e316fb68",
  measurementId: "G-9FZV2WXHHR"
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
//firebase.analytics()

const analytics = firebase.analytics()

export { analytics }