<template>
  <v-app>
    <AppBar />

    <v-main>
      <router-view/>
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import AppBar from './components/AppBar'
import Footer from './components/Footer'

export default {
  name: 'App',

  data: () => ({
    //
  }),
  components: {
    AppBar, Footer,
  },
}
</script>

<style scoped>
@font-face {
  font-family: 'Humanist 521 Ultra Bold';
  src: url("./assets/fonts/humanist-521-ultra-bold.otf") format("opentype");
  font-style: normal;
}
</style>